<template>
  <v-content class="point" style="background-color: #f9f9f9;">
    <v-tabs
      grow
      fixed
      v-model="tabs"
      class="px-4"
      background-color="transparent"
      :color="maincolor"
    >
      <v-tab href="#status" class="caption">ステータス</v-tab>
      <v-tab href="#user" class="caption">ユーザー情報</v-tab>
      <v-tab href="#get_ticket" class="caption">取得済みチケット</v-tab>
    </v-tabs>

    <v-tabs-items class="" v-model="tabs" style="background-color: #f9f9f9;">
      <!--ステータスタブ -->
      <v-tab-item class="px-4 pt-5" value="status" style="min-height: 100%;">
        <div class="text-center">
          <v-chip small class="maincolor-bg">現在までの取得ポイント</v-chip>
          <p class="my-2 fontsize11 maincolor" v-if="point.last_points_earned_type">
            {{point.last_points_earned_type}}
            <span class="subtitle-2 font-weight-bold">{{point.last_points_earned}}</span>
          </p>
          <p class="my-5 fontsize11 maincolor" v-else>
          </p>
        </div>

        <div class="text-center">
          <v-progress-circular
            :rotate="-90"
            :width="15"
            :value="value"
            :color="maincolor"
            :size="point.rank_detail ? '213' : '295'"
          >
            <div class="">
              <span class="bigpoint"
                ><AnimatedNumber :value="num"></AnimatedNumber></span
              ><br />
              <span class="bigpoint2 maincolor">/ </span
              ><span class="smallpoint">{{point.grant_point}}</span
              ><span class="xsmallpoint">point</span><br />
              <span class="overline maincolor font-weight-bold"
                >コンプリートまであと</span
              ><br />
              <span class="bigpoint3">{{point.grant_point - point.point}}</span
              ><span class="xsmallpoint maincolor">point</span>
            </div>
          </v-progress-circular>
        </div>

        <div class="text-center my-4">
          <v-divider></v-divider>
          <p
            class="fontsize11 mb-0 py-1 font-weight-bold"
            style="color: #9d9d9d;"
          >
            ポイント有効期限：{{point.expiration_date}}
          </p>
          <v-divider></v-divider>
        </div>

        <!-- ランク表示 -->
        <RankView
          :item="point"
          v-if="point.rank_detail"
          ref="rankView"
          @openDialog="showRankDialog"
        ></RankView>
      </v-tab-item>

      <!-- ユーザー情報タブ -->
      <v-tab-item value="user" class="">
        <UserInfo :history="history" :init="init"></UserInfo>
      </v-tab-item>

      <!-- 取得済みチケットタブ -->
      <v-tab-item value="get_ticket" class="px-4 mb-6">
        <CompleteTicket :init="init" :point="point"></CompleteTicket>
      </v-tab-item>
    </v-tabs-items>

    <!-- ランクについてダイアログ -->
    <RankDialog :item="point" :init="init" ref="rankDialog"></RankDialog>

    <!-- ランクアップダイアログ -->
    <RankUpDialog :item="rankupinfo" :init="init" ref="rankUpDialog"></RankUpDialog>
  </v-content>
</template>

<script>
import CompleteTicket from "@/views/point/CompleteTicket.vue";
import UserInfo from "@/views/point/UserInfo.vue";
import RankView from "@/views/point/RankView.vue";
import RankDialog from "@/views/point/RankDialog.vue";
import RankUpDialog from "@/views/point/RankUpDialog.vue";
import AnimatedNumber from "@/components/AnimatedNumber.vue";
export default {
  components: {
    CompleteTicket,
    UserInfo,
    RankView,
    RankDialog,
    RankUpDialog,
    AnimatedNumber,
  },
  data: () => ({
    init: [],
    isExpand: [0],
    maincolor: null,
    tabs: "model",
    value: 0,
    num: 0,
    point: null,
    history: null,
    rankupinfo: null,
  }),
  async created () {
    this.init = this.storageGet("*")
    this.maincolor = this.init.color_code

    try {
        //ポイント情報取得API
        var req = {params: {
          'sid': this.init.sid,
          'key': this.init.key,
          'device_id': this.init.device_id,
          'device_uid': this.init.device_uid,
          'os': this.init.os,
          'app_version': this.init.app_version,
        }}

        var res = await this.apiCall('/point', req);
        if (!res) return false;
        this.$set(this, 'point', res);

        //ポイントランク情報API ランクアップしているかどうかを確認する
        if(this.point.rank != null){ //ランク機能を利用しているかどうか判別
          //ポイント情報取得API
          var rankupReq = {
            'sid': this.init.sid,
            'key': this.init.key,
            'device_id': this.init.device_id,
            'device_uid': this.init.device_uid,
            'os': this.init.os,
            'app_version': this.init.app_version,
          }
          var rankupRes = await this.apiCall('/point/get-rank', rankupReq, 'post');
          if (!rankupRes) return false;
          this.$set(this, 'rankupinfo', rankupRes);

          if(this.rankupinfo.rankuped == 1){ //ランクアップしていた場合、ランクアップダイアログを表示
            this.$refs.rankUpDialog.open()
          }
        }

        //ポイント履歴取得API
        var res_history = await this.apiCall('/point/history', req);
        if (!res_history) return false;
        this.$set(this, 'history', res_history);

    } catch(e) {
        this.loading = false
        this.callDialog('接続エラー', '一時的なエラーです。ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    showRankDialog() {
      this.$refs.rankDialog.open();
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.value = (this.point.point / this.point.grant_point) * 100;
      this.num = this.point.point;
    }, 1500);
  },
};
</script>

<style scoped>
.bigpoint {
  font-size: 54px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
  line-height: 1;
}
.bigpoint2 {
  font-size: 26px;
  font-family: "Oswald", sans-serif !important;
  line-height: 1;
}
.bigpoint3 {
  font-size: 20px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  line-height: 1;
}
.smallpoint {
  font-size: 18px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
}
.xsmallpoint {
  font-size: 12px;
  font-weight: bold;
  font-family: "Oswald", sans-serif !important;
  color: #333333;
}
</style>
<style>
.v-application--wrap {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
