<template>
  <div>
    <v-card class="mt-5 pb-7" v-for="(item, index) in this.data" :key="item.id">
      <v-img :src="item.image_path" style="border-radius: 16px 16px 0 0; height: 163px;"></v-img>
      <p class="mt-4 mb-0 text-center" style="font: bold 12px/20px 'Roboto'; color: #FF00B1;">
        コンプリートチケット
      </p>
      <p class="mt-2 mb-0 text-center" style="font: bold 16px/20px 'Roboto'; color: #FF00B1;">
        {{item.title}}
      </p>
      <!-- NEW_DEV-1716 cyber start -->
      <p class="mt-4 mb-0 text-center" style="font: bold 11px/16px 'Roboto'; color: #9d9d9d;">
      <!-- NEW_DEV-1716 cyber end -->
        チケット有効期限：{{item.expiration_date}}
      </p>
      <!-- NEW_DEV-1690 cyber start -->
      <div class="mt-4 mb-4 mx-4" style="font: normal 16px/20px 'Roboto'; color: #333333;" v-html="item.privilege_text">
      <!-- NEW_DEV-1690 cyber end -->
      </div>
      <hr class="mt-4 mx-3" size="1px" color="#DEDEDF">
      <v-row align="start" justify="center" no-gutters class="mt-4 mx-3">
        <v-col cols="3">
          <p class="mb-0" style="font: bold 11px/18px 'Roboto'; color: #9D9D9D;">
            注意事項
          </p>
        </v-col>
        <v-col cols="9">
          <p class="mb-0" style="font: normal 11px/16px 'Roboto'; color: #333333;" v-html="item.attention"></p>
        </v-col>
      </v-row>
      <hr class="mt-4 mx-3" size="1px" color="#DEDEDF">
      <div class="mt-6 text-center">
        <v-btn x-small width="200px" height="32px" class="maincolor-bg" dark @click.stop="openUseDialog(item, index)">
          <span style="font: bold 14px/24px 'Roboto';">
            取得チケットを使う
          </span>
        </v-btn>
      </div>

			<v-overlay :value="tes" absolute opacity="0.7" v-if="item.is_use === 1">
				<div class="text-center">
					<img class="img" src="@/assets/check.svg" width="30">
					<!-- NEW_DEV-1700 cyber start -->
					<p class="flgtext"><br>チケット使用済み</p>
					<!-- NEW_DEV-1700 cyber end -->
				</div>
				<div class="text-center pt-5">
					<v-btn rounded class="graybtn" width="100%" @click.stop="onOpenDialog(item.id, index)">削除する</v-btn>
				</div>
			</v-overlay>

			<v-overlay absolute opacity="0.7" v-else-if="item.is_expiration === 1">
				<div class="text-center">
					<img class="img" src="@/assets/sandtimer.svg" width="30">
					<p class="flgtext">有効期限切れ</p>
				</div>
				<div class="text-center pt-5">
					<v-btn rounded class="graybtn" width="100%" @click.stop="onOpenDialog(item.id, index)">削除する</v-btn>
				</div>
			</v-overlay>
    </v-card>

    <appDialogue ref="appDialogue" @use="use"></appDialogue>
    <ticketConfirmDialog ref="ticketConfirmDialog" @use="use"></ticketConfirmDialog>

    <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" spinner="spiral">
      <div slot="spinner">ロード中...</div>
      <div slot="no-more"></div>
      <div slot="no-results" class="mt-10">現在取得済のチケットはありません</div>
    </infinite-loading>

    <v-dialog v-model="dialog" persistent>
      <v-card class="ticket_dialogue text-center pa-5">
        <p class="body-2 mb-10">チケットを削除しますか？</p>
        <div class="text-center">
          <v-btn rounded class="maincolor-bg default_button" @click.stop="onDeleteTicket(ticket_id, ticket_index)">削除する</v-btn>
          <br><br>
          <v-btn rounded class="caption font-weight-black mb-10" style="background-color:#EFEFEF;" @click="dialog = false">キャンセルする</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- チケット利用認証ダイアログ -->
    <appDialogue ref="appDialogue" @use="use"></appDialogue>
    <!-- チケット使用ダイアログ -->
    <ticketConfirmDialog ref="ticketConfirmDialog" @use="use"></ticketConfirmDialog>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import ticketConfirmDialog from '../../components/ticket_confirm_dialog.vue'
import appDialogue from '../../components/ticket_dialogue.vue'

export default {
    components: {
        InfiniteLoading,
        ticketConfirmDialog,
        appDialogue,
    },
    props: ['point', 'init'],
    data: () => ({
        data: [
        ],
        result: [],
        page: 1,
        ticket_id: null,
        ticket_index: null,
        dialog: false,
        target_id: null,
    }),
    methods: {
        async use(code) {
                try {
                  //loadingを表示
                  this.loading = true
                  //念の為もう一度ローカルから取得（後ほど処理変える）
                  this.init = this.storageGet('*')

                  var params = {
                    'sid': this.init.sid,
                    'device_uid': this.init.device_uid,
                    'device_id': this.init.device_id,
                    'os': this.init.os,
                    'key': this.init.key,
                    'id': this.target_id,
                    'code': code,
                    'type': "1",
                    'shop_id': String(this.init.sid),
                  }

                  var res = await this.apiCall('/user/ticket/use', params, 'post', 0);
                  if (!res) {
                    this.loading = false;
                    return false
                  }

                  this.$set(this, 'result', res);

                  this.loading = false

                  this.data[this.ticket_index]['is_use'] = 1;

                } catch(e) {
                  this.loading = false
                  this.callDialog('エラー', 'エラーが発生いたしました。', 3);
                }
        },
        async openUseDialog(item, index) {

            // 対象のIDを保存しておく
            this.target_id = item.id
            this.ticket_index = index

            if (item.auth_flg == 1) {

                var qr_url = "useTicket://?id=" + item.id + "&device_uid=" + this.init.device_uid + "&type=1" + "&os=" + this.init.os + "&device_id=" + this.init.device_id + "&sid=" + this.init.sid

                // ダイアログ表示
                this.$refs.appDialogue.open(item.id, item.auth_flg, qr_url);

                var count = 0
                var baseData = this.storageGet('*')
                var apiCall  = this.apiCall
                var router = this.$router
                var callDialog = this.callDialog

                var useCheck = async function() {
                console.log("checking...")
                  count++

                  var params = { params:{
                    'sid': baseData.sid,
                    'service_cd': baseData.service_cd,
                    'device_uid': baseData.device_uid,
                    'device_id': baseData.device_id,
                    'os': baseData.os,
                    'key': baseData.key,
                    'id': item.id,
                    'page': 1,
                  }}

                  try{
                    var res = await apiCall('/point/ticket', params, 'get');
                    if (res.is_use == 1) {
                        router.go({path: router.currentRoute.path, force: true});
                    }
                    if (!res) return false;
                  } catch(e) {
                      callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                  }
                }

                var id = setInterval(function(){
                    useCheck();
                    if (count >= 1){
                        clearInterval(id);
                    }
                }, 1000);

            } else {
                this.$refs.ticketConfirmDialog.open()
            }

        },
        onClickBtn(id, flg) {
          this.$refs.compdialog.open(id, flg);
        },
        onUseTicket(id) {
          this.$refs.confirmdialog.open(id);
        },
        onOpenDialog(id, index) {
          this.ticket_id = id
          this.ticket_index = index
          this.dialog = true
        },
        async onDeleteTicket(id, index) {

          var req = {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'id': id,
          }

          var res = await this.apiCall('/point/ticket/delete', req, 'post', 0)

          if (!res) return false;

          this.$delete(this.data, index)
          this.dialog = false
        },
        async infiniteHandler() {

          var req = { params: {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'page': this.page
          }}

          var res = await this.apiCall('/point/ticket', req)

          if (this.page == 1) {
              this.$set(this, 'result', res)
              this.$set(this, 'data', res.data)
          } else {
              this.data = this.data.concat(res.data);
          }

          this.page = this.page + 1

          if (res.data && res.data.length > 0) {
              this.$refs.infiniteLoading.stateChanger.loaded()
          } else {
              this.$refs.infiniteLoading.stateChanger.complete()
          }

          if (!this.data || !this.data.length) {
            this.scrollMoveTop();
          } else {
            for (let i = 0; i < this.data.length; ++i) {
              this.data[i].attention = this.data[i].attention.replace(/\n/g, '<br/>');
            }
          }
        },
    },
    created() {
    },
}
</script>
